<!--
  An error message related to a specific input field.
-->
<script setup lang="ts">import { computed as _computed } from 'vue';

withDefaults(defineProps<{
  errors: string | string[]
}>(), {  })

const errorMessages = _computed(() => typeof __props.errors === 'string' ? __props.errors : __props.errors.join(', '))
</script>

<template>
  <div
    v-if="errorMessages"
    class="input-error text-red-base font-medium whitespace-pre-wrap"
  >{{ errorMessages }}</div>
</template>
